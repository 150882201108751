import React from 'react';

import {Breadcrumbs} from '@mui/material';

import {withErrorBoundary} from 'components/error/ErrorBoundary';

import {CustomIcon} from '../CustomIcon/CustomIcon';
import {NextLink} from '../NextLink/Link';
import Typography from '../Typography';
import styles from './Breadcrumb.module.scss';

const Breadcrumb = ({link}) => {
  return (
    <Breadcrumbs
      sx={{color: 'inherit', '& .MuiBreadcrumbs-separator': {margin: 0}}}
      separator={<CustomIcon name="chevronRight" style={{height: '24px', width: '24px'}} />}
      aria-label="breadcrumb"
    >
      <NextLink className={styles.link} href="/">
        <Typography className={styles.linkText}>Home</Typography>
      </NextLink>
      {link.map(link =>
        link.link || link?.page?.data?.attributes?.fullSlug ? (
          <NextLink
            key={link.link ? link?.link : link?.page?.data?.attributes?.fullSlug}
            href={link?.link ? link?.link : link?.page?.data?.attributes?.fullSlug}
            passHref
            className={styles.link}
          >
            <Typography className={styles.linkText}>{link?.linkText}</Typography>
          </NextLink>
        ) : (
          <Typography className={styles.linkText} key={link.linkText}>
            {link?.linkText}
          </Typography>
        ),
      )}
    </Breadcrumbs>
  );
};

export default withErrorBoundary(Breadcrumb, ' Breadcrumb');
