import {useTranslation} from 'hooks/translations/useCustomTranslation';
import {getLocalePrefix} from 'hooks/useLocalePrefix';
import {GetServerSideProps, InferGetServerSidePropsType} from 'next';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';
import Head from 'next/head';
import Image from 'next/image';
import {useRouter} from 'next/router';
import BackgroundImage from 'public/assets/notFoundPageBG.jpg';
import React from 'react';

import {SharedSeoComponent} from 'lib/api/strapi';

import {DefaultPagePropsProvider, TDefaultProps} from 'utils/dataFetching/DefaultPagePropsProvider';
import getDefaultPageProps from 'utils/dataFetching/getDefaultPageProps';

import {Box, Button, Typography} from 'components/basic-components';
import Breadcrumb from 'components/basic-components/Breadcrumb/Breadcrumb';
import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';
import styles from 'components/error/errorPage.module.scss';
import ContentWrapper from 'components/layout-components/ContentWrapper/ContentWrapper';
import Seo from 'components/strapi-components/Seo';

import {translationFiles} from '../utils/translations/constants';

export const getStaticProps: GetServerSideProps = async ({locale, locales}) => {
  return {
    props: {
      defaultProps: await getDefaultPageProps({locale, locales}),
      ...(await serverSideTranslations(locale, translationFiles)),
    }, // will be passed to the page component as props
  };
};

const Custom404Page: InferGetServerSidePropsType<typeof getStaticProps> = ({
  defaultProps,
}: {
  defaultProps: TDefaultProps;
}) => {
  const {t} = useTranslation('404');
  const router = useRouter();
  const {locale, defaultLocale} = router;
  const errorPageSeoData = {
    metaTitle: t('metaTitle'),
    metaDescription: t('metaDescription'),
    metaRobots: 'noindex, follow',
  } as SharedSeoComponent;

  return (
    <DefaultPagePropsProvider defaultProps={defaultProps}>
      <div style={{zIndex: 1}}>
        <Seo seoData={errorPageSeoData || undefined} />
        <Head>
          {/* {metaTags} */}
          <title>{t('metaTitle')}</title>
        </Head>
        <Box className={styles.backgroundImageContainer}>
          <Image alt={t('error')} src={BackgroundImage} className={styles.image} />
          <ContentWrapper>
            <Box className={styles.content}>
              <Breadcrumb
                link={[
                  {
                    link: null,
                    linkText: '404',
                  },
                ]}
              />
              <Typography className={styles.contentTitle}>{t('errorMessage')}.</Typography>
              <Button
                href={`${getLocalePrefix({locale, defaultLocale, slash: 'front'})}/`}
                startIcon={<CustomIcon name="arrowAlt" />}
                className={styles.contentButton}
              >
                {t('goBack')}
              </Button>
            </Box>
          </ContentWrapper>
        </Box>
      </div>
    </DefaultPagePropsProvider>
  );
};

export default Custom404Page;
